<div mat-dialog-content>
    <mat-card class="card">
        <div fxLayout="row" fxLayoutGap="0.5vw">
            <div fxFlex="50" fxLayout="column" fxGrow="0" fxLayoutAlign="center center" fxLayoutGap="1vh">
                <figure class="card-profile-image" fxFlex fxGrow="0" (click)="showImageCropper()"
                    [class.disabled]="!firstNameFormControl.enabled" [class.enabled]="firstNameFormControl.enabled">
                    <img [src]="pictureLink" style="width: 100px; height:100px;"
                        class="circle z-depth-2 responsive-img activator">
                </figure>
                <!-- <button mat-raised-button fxGrow="0" fxFlex (click)="showImageCropper()">{{'Select Image' |
                translate}}</button> -->
                <span class="name" fxGrow="0" fxFlex>{{userObj.name}}</span>
                <span class="email" fxGrow="0" fxFlex>{{userObj.email}}</span>
                <span class="role" fxGrow="0" fxFlex>{{userObj.role}}</span>
            </div>
            <mat-divider class="divider" fxFlex [vertical]="true"></mat-divider>
            <div fxLayout="column" fxLayoutGap="0vw" style="width: 100%;">
                <mat-form-field fxFlex>
                    <mat-label>{{'First Name' | translate}}</mat-label>
                    <input matInput placeholder="" required [formControl]="firstNameFormControl" maxlength="50">
                    <mat-error *ngIf="firstNameFormControl.hasError('required')">
                        {{'First name is required' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex>
                    <mat-label>{{'Last Name' | translate}}</mat-label>
                    <input matInput placeholder="" required [formControl]="lastNameFormControl" maxlength="50">
                    <mat-error *ngIf="lastNameFormControl.hasError('required')">
                        {{'Last name is required' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex>
                    <mat-label>{{'Occupation' | translate}}</mat-label>
                    <input matInput placeholder="" [formControl]="occupationControl" maxlength="100">
                </mat-form-field>
                <mat-form-field fxFlex>
                    <mat-label>{{'Organization' | translate}}</mat-label>
                    <input matInput placeholder="" [formControl]="organizationControl" maxlength="100">
                </mat-form-field>
                <mat-form-field fxFlex>
                    <mat-label>{{'Email' | translate}}</mat-label>
                    <input matInput placeholder="" [formControl]="emailControl" readonly>
                    <mat-error *ngIf="emailControl.hasError('required')">
                        {{'Email is required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="emailControl.hasError('email')">
                        {{'Please enter a valid email address' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex>
                    <mat-label>{{'Mobile Number' | translate}}</mat-label>
                    <input matInput placeholder="" [formControl]="phoneFormControl" maxlength="100">
                    <mat-error *ngIf="phoneFormControl.hasError('required')">
                        {{'Mobile number is required' | translate}}
                    </mat-error>
                </mat-form-field>
                <div fxFlex fxLayoutAlign="end" class="edit">
                    <mat-icon (click)="edit()" [matTooltip]="'Edit' | translate">edit</mat-icon>
                </div>
            </div>
        </div>
    </mat-card>
</div>
<div mat-dialog-actions align="start">
    <div fxLayout="row" style="width: 100%; margin: 0 0.5vw;" fxLayoutGap="0.5vw">
        <div fxFlex fxGrow="0">
            <button mat-raised-button (click)="closeDialog()">{{'Cancel' |
                translate}}</button>
        </div>
        <div fxFlex fxGrow="0" *ngIf="firstNameFormControl.enabled">
            <button mat-raised-button color="primary" (click)="updateUser()">{{'Update' |
                translate}}</button>
        </div>
        <div fxFlex fxGrow="0" *ngIf="!firstNameFormControl.enabled">
            <button mat-raised-button [disabled]="true" style="cursor: not-allowed;">{{'Update' |
                translate}}</button>
        </div>
        <div fxFlex fxGrow="1">
            <button mat-raised-button color="primary" (click)="resetPassword()">{{'Change Password' |
                translate}}</button>
        </div>
        <mat-slide-toggle fxFlex fxLayoutAlign="end center" [(ngModel)]="dnd" (ngModelChange)="saveDnd()">{{'Do not
            disturb' | translate}}
        </mat-slide-toggle>
    </div>
</div>