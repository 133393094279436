export const environment = {
  production: false,
  orgId: '64c0ac2fecacd2eea6b667f9',
  serverUrl: 'https://staging.api.wrna.interactivelife.me/wrna/',
  identityName: 'WRNA',
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',
    roleCaseManager: '64dc639a4096f85368c00770',
    roleSupervisor: '64dc63c44096f85368c00772',
    roleClient: '64dc69d44096f85368c00776',
  },
  lockSite: false,
  rollbar: {
    apiKey: 'd21a6d0510cd40c3b15bb29bfb24e043',
  },
  environment: 'local',
  casUrl: 'https://staging.api.wrna.interactivelife.me/wrna/',
  productId: 'fec86ca1-d772-4745-a0ef-1cb8ce2c0400',
  formLibrariesURL: 'https://staging.api.formlib.interactivelife.me/api/',
  serverTileUrl: 'https://staging.api.formlib.interactivelife.me/api/',
  projectName: 'wrna',
  orgType: 'wrna',
  s3: 'https://stellar-images-bucket-staging.s3.amazonaws.com/',
  appId: '64c0b29becacd2eea6b66801',
  locationId: '64c0b390ecacd2eea6b66803',
  aliveAppIntervalMinutes: 30, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 60, // if you keep app inactive pop a message
  firebaseVapidKey: 'BCLDDmne7U-VybZ3r3czg3Pzr7LcEJsfuxGp3_J77RGcVHI1i6w7QGD4G05vnvZuqmPucl2yiZqZKslIsUr2wsw',
  firebaseConfig: {
    apiKey: "AIzaSyAiQcz5XVk6aitQza-EZqqqPUpIDJ5efMA",
    authDomain: "wrna-2072b.firebaseapp.com",
    projectId: "wrna-2072b",
    storageBucket: "wrna-2072b.appspot.com",
    messagingSenderId: "107184913401",
    appId: "1:107184913401:web:a02a5b0ba76dd595665b28",
    measurementId: "G-YL8NV3LD0Z"
  },
  permissions: {
    roles: {
      "5eb137f1d3123a407c7c97cc": {// admin
        pages: {
          "rules": ["show", "create", "read", "update", "delete"],
          "tiles": ["show", "create", "read", "update", "delete"],
          "procedures": ["show", "create", "read", "update", "delete"],
          "score-reports": ["show", "create", "read", "update", "delete","mappedScore"],
          "evaluation": ["show", "create", "read", "update", "delete"],
        }
      },
      "64dc639a4096f85368c00770": {// CaseManager
        pages: {
          "rules": ["show", "read"],
          "tiles": [],
          "procedures": [],
          "score-reports": [],
          "evaluation": [],
        },
      },
      "64dc63c44096f85368c00772": {// Supervisor
        pages: {
          "rules": ["show", "read"],
          "tiles": [],
          "procedures": [],
          "score-reports": [],
          "evaluation": [],
        },
      },
      "64dc69d44096f85368c00776": {// client
        pages: {
          "rules": [],
          "tiles": [],
          "procedures": [],
          "score-reports": [],
          "evaluation": [],
        },
      }
    }
  }
};
